import Vue from 'vue';

const state = {
  progresos : {},
  detalle : {},
};

const actions = {
  
  fetchProgreso:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso',data).then(
        response =>{
          commit('setProgreso',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDetalle:({commit},agendamiento_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/detalle',{agendamiento_id:agendamiento_id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveNota:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/guardar_nota',data).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  saveExam:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('progreso/guardar_examen',data).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getProgreso: (state) => (estudiante_id) => {
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})
    }
    return state.progresos[estudiante_id]
  },
  getProgresoDetalle: (state) => (id) =>{
    if(!state.detalle[id]){
      Vue.set(state.detalle, id, {})
    }
    return state.detalle[id]
  }
};

const mutations = {
  setProgreso : (state, datos) => {
    let estudiante_id = datos.id
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})  
    }
    state.progresos[estudiante_id] = datos
  },
  setDetalle: (state, detalle) => {
    let id = detalle.id;
    Vue.set(state.detalle, id, detalle)
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};


<template>
  <div class="container mt-3">
    <div v-if="progreso && progreso.resultado" class="m-auto">
      <div class="row">
        <h6 class="col-md-9 col-sm-12">Examen presentado: <strong>{{examen.nombre}}</strong></h6>
        <h6 class="col-md-3 col-sm-12" v-if="estructura.self_assessment"><strong>Self Assessment</strong></h6>
      </div>
        <div v-for="criterio in estructura.criterios">
          <b-row>
            <b-col cols="7" md="7"><strong>{{criterio.nombre}} :</strong> </b-col>
            <b-col cols="2" md="2"><span>{{progreso.resultado.respuestas[criterio.codigo]}}</span></b-col>
            <b-col cols="3" md="3">
              <span>
                <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'bien'" class="viewIcon fa fa-thumbs-o-up" aria-hidden="true"></i>
                <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'normal'" class="viewIcon fa fa-hand-paper-o"></i>
                <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'mal'" class="viewIcon fa fa-thumbs-o-down" aria-hidden="true"></i>
              </span>
            </b-col>
          </b-row>

          <!--div class="col-xs-4 col-sm-4 col-md-6">
            <strong>{{criterio.nombre}} :</strong> 
          </div>
          <div class="col-xs-4 col-sm-4 col-md-3 viewNum">
            <span>{{progreso.resultado.respuestas[criterio.codigo]}}</span>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-3" v-if="estructura.self_assessment">
            <span>
              <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'bien'" class="viewIcon fa fa-thumbs-o-up" aria-hidden="true"></i>
              <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'normal'" class="viewIcon fa fa-hand-paper-o"></i>
              <i v-if="progreso.resultado.respuestas[criterio.codigo+'-self'] == 'mal'" class="viewIcon fa fa-thumbs-o-down" aria-hidden="true"></i>
            </span>
          </div-->
        </div>

      <div class="row">
        <h5 class="col-md-7">
        Nota: {{progreso.nota}}  
        </h5>
        <div v-if="!estructura.self_assessment" class="col-md-4">
          <h6 class="col-md-8"><strong>Self Assessment</strong></h6>
          <span class="col-md-4">
            <i v-if="progreso.resultado.respuestas['self-general'] == 'bien'" class="viewIcon fa fa-thumbs-o-up" aria-hidden="true"></i>
            <i v-if="progreso.resultado.respuestas['self-general'] == 'normal'" class="viewIcon fa fa-hand-paper-o"></i>
            <i v-if="progreso.resultado.respuestas['self-general'] == 'mal'" class="viewIcon fa fa-thumbs-o-down" aria-hidden="true"></i>
          </span>
        </div>
        <h5 class="col-md-8">
          Comments: {{progreso.resultado.docente_comentarios}}  
        </h5>
      </div>
    </div>
    <div v-else class="m-auto" >
    	<h6>Examen pendiente:</h6>
      <h4 v-if="examen.nombre">{{examen.nombre}}
        <h6>
          <b-badge pill v-if="examen.codigo" class="">{{examen.codigo}} Version {{examen.version}}</b-badge>
        </h6>
      </h4>
      <div v-if="examen.objetivo">
        <div class="row">
          <strong class="col-3">GOAL:</strong>
          <div class="col-md-6 col-xs-12 col-sm-12">{{examen.objetivo}}</div>
        </div>
      </div>
      <b-alert :show="!agendamiento.tema.rango_nota ? true : false" variant="danger"><label>El tema no tiene un rango de nota, por favor comuniquese con el coordinador academico.</label></b-alert>
      <div v-if="estructura" >
        <b-card :title="estructura.unidades" class="mt-2 mb-2">
          <b-card-text class="col-md-12 col-xs-12 col-sm-12">
              <div class="row">
                <div class="col-md-9"></div>
                <h5 class="col-md-3" v-if="estructura.self_assessment"><strong>Self Assessment</strong></h5>
              </div>
              <b-alert :show="msgError ? true : false" variant="danger"><label>{{msgError}}</label></b-alert>
              <div v-for="(criterio, index) in estructura.criterios" :key="index" class="row">
                  <strong class="col-md-2 col-xs-12 col-sm-12">{{criterio.nombre}}:</strong>
                  <div class="m-auto col-md-7 col-xs-12 col-sm-12">
                    <div v-if="criterio.tipo == 'discreto'">
                      <b-form-select v-model="!respuestas[criterio.codigo] ? respuestas[criterio.codigo] = 'Select a rating...' : respuestas[criterio.codigo]" :options="criterio.opciones"></b-form-select><br><br>

                    </div>
                    <div v-else>
                      <div class="row">
                        <div class="col-md-4 col-xs-10 col-sm-10">
                          <b-form-input type="number" class="mb-3 col-md-12 col-xs-12 col-sm-12" @change="valNumber(index, criterio.codigo)" v-model="respuestas[criterio.codigo]" placeholder="Calificación"></b-form-input>
                        </div>
                        <div class="col-md-3 col-xs-2 col-sm-2">
                          <span>/ {{criterio.opciones.length}},0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="estructura.self_assessment" class="mb-3 col-md-3 col-xs-12 col-sm-12">
                    <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas[criterio.codigo+'-self'] == 'bien' ? 'select' : 'unselect'" :id="criterio.codigo+'-self-bien'" @click="selectedHand(criterio.codigo+'-self', 'bien')">
                      <h4><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></h4>
                    </b-button>
                    <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas[criterio.codigo+'-self'] == 'normal' ? 'select' : 'unselect'" :id="criterio.codigo+'-self-normal'" @click="selectedHand(criterio.codigo+'-self','normal')">
                      <h4><i class="fa fa-hand-paper-o"></i></h4>
                    </b-button>
                    <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas[criterio.codigo+'-self'] == 'mal' ? 'select' : 'unselect'" :id="criterio.codigo+'-self-mal'" @click="selectedHand(criterio.codigo+'-self','mal')">
                      <h4><i class="fa fa-thumbs-o-down" aria-hidden="true"></i></h4>
                    </b-button>
                    <span class="d-md-none d-lg-none">
                      <hr>
                    </span>
                  </div>
              </div>

              
          </b-card-text>

        </b-card>

      <div class="row mb-2">
        <strong class="col-2">Comments:</strong>
        <div class="col-md-5 col-xs-12 col-sm-12">
          <b-form-textarea
            v-model="docente_comentarios"
            name="docente_comentarios"
            class="form-control"
            placeholder="Write some comment"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div v-if="!estructura.self_assessment" class="col-md-4 col-xs-12 col-sm-12">
          <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas['self-general'] == 'bien' ? 'select' : 'unselect'" :id="'self-general-bien'" @click="selectedHand('self-general', 'bien')">
            <h4><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></h4>
          </b-button>
          <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas['self-general'] == 'normal' ? 'select' : 'unselect'" :id="'self-general-normal'" @click="selectedHand('self-general', 'normal')">
            <h4><i class="fa fa-hand-paper-o"></i></h4>
          </b-button>
          <b-button pill variant="outline-primary" class="iconButton" :class = "respuestas['self-general'] == 'mal' ? 'select' : 'unselect'" :id="'self-general-mal'" @click="selectedHand('self-general','mal')">
            <h4><i class="fa fa-thumbs-o-down" aria-hidden="true"></i></h4>
          </b-button>
        </div>  
      </div>

      </div>
      <div v-else>La estructura del examen no existe o es incorrecta</div>
      
      <button v-if="puedeGuardar" class="btn btn-block btn-danger mt-2" @click="procesarGuardar">
        Guardar
      </button>

    </div>
  </div>
</template>
<style>
  .alert {
    position: relative;
    padding: 0.05rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .select{
    background:#007bff !important;
    color:white !important;
  }
  .select .fa:hover{
    color:white !important;
  }
  .unselect{
    background:none !important;
  }
  .iconButton{
    width: 55px;
    height: 38px;
  }
  .iconButton:hover{
    color:#007bff !important;
  }
  .active{
    background:#007bff !important;
  }
  .viewIcon{
    padding: 0px 61px 0px;
    font-size:27px !important;
  }
  .viewNum{
    padding: 0px 61px 0px;
    font-size:20px !important;
  }
</style>
<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';
  import ClasesDetalleAgendamiento from '@/components/Clases/ClasesDetalleAgendamiento.vue';  

  export default {
    name: 'ClasesDetalleExamen',
    components: {
      ClasesDetalleAgendamiento
    },
    data () {
      return {
        progreso:null,
        nota:null,
        respuestas: {},
        docente_comentarios: null,
        msgError:""
      }
    },
    props: {
      agendamiento: Object
    },
    mounted () {
      
      if(this.agendamiento){
        this.fetchDetalle(this.agendamiento.id).then((progreso)=>{
          this.progreso = progreso
        })
      }else{
        console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente ClasesIngresarNota')
      }

      
      for(let criterio of this.estructura.criterios){
        if(this.estructura.self_assessment){
          Vue.set(this.respuestas,criterio.codigo + "-self",null)  
        }
        Vue.set(this.respuestas,criterio.codigo,null)  
      }1
      if(!this.estructura.self_assessment){
        Vue.set(this.respuestas,"self-general",null)  
      }
    },
    methods:{
      ...mapActions({
        
        saveExam: 'progresos/saveExam',
        fetchDetalle: 'progresos/fetchDetalle',
        setWarning: 'setWarning',
        fetchClase: 'clases/fetchClase',
      }),
      ...mapMutations({
        
      }),
      viewMsg(code, msg){
        this.respuestas[code] = "";
        this.msgError = msg;
      },
      valNumber(idx, code){
        var max = this.estructura.criterios[idx].opciones.length;
        this.respuestas[code] = Number(this.respuestas[code]).toFixed(1);
        if(this.respuestas[code]){
          this.msgError = "";
          if(Number(this.respuestas[code]) > Number(max)){
            this.viewMsg(code, "Formato de número invalido, la calificación maxima es: "+max+" en el criterio " + this.estructura.criterios[idx].nombre);
          }
          if(Number(this.respuestas[code]) < Number(0)){
            this.viewMsg(code, "Formato de número invalido, la calificación mínima es: 0 en el criterio " + this.estructura.criterios[idx].nombre);
          }
        }
      },     	
      limpiar(){
        
      },
      selectedHand(posicion, val){
        this.respuestas[posicion] = val;
      },
      procesarGuardar: function () {
        
        let payload = {
          agendamiento_id: this.agendamiento.id,
          respuestas: this.respuestas,
          docente_comentarios: this.docente_comentarios
        }
        this.saveExam(payload).then(data=>{
            this.fetchDetalle(this.agendamiento.id).then((progreso)=>{
              this.progreso = progreso
              window.location.href = "/#"+this.$route.path
            });
        }).catch(error=>{
          this.setWarning(error, { root: true }).then(()=>{})
        })
        
      },

    },
    computed:{
      ...mapState({
      }),
      ...mapGetters({
        
      }),
      examen(){
        return this.agendamiento.tema.examen
      },
      estructura (){
        if(this.examen.estructura){
          return this.examen.estructura
        }
        return null
      },
      puedeGuardar(){
        for(let criterio of this.estructura.criterios){
          if(this.respuestas[criterio.codigo] == null){
            return false
          }
          if(this.estructura.self_assessment){
            if(this.respuestas[criterio.codigo+'-self'] == null){
              return false
            }
          }else{
            if(this.respuestas['self-general'] == null){
              return false
            }
          }
        }
        return true
      },
      resultado(){
        return JSON.stringify(this.progreso.resultado,null,2)
      }

      
    }
  }
</script>





<template>
  <div class="">
    <div class="progreso row" v-if="progreso_fila">
      <div class="col">
          <strong>{{textoTema}}</strong><br>
      </div>
      <div class="col">
        <strong>{{textoEstado}}</strong><br>
      </div>

      <div class="col">
        <strong v-if="progreso_fila.nota">{{progreso_fila.nota}}/{{progreso_fila.rango_nota}}</strong>
        
      </div>
      
    </div>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  import utils from '@/modules/utils';

  export default {
    name: 'ProgresoRegistro',
    components: {
    },
    data () {
      return {
        
      }
    },
    props: {
      progreso_fila: Object
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
    },
    computed: {
      ...mapState({
      }),
      ...mapGetters({
      }),
      
      textoEstudiante(){
        let texto = ''
        if(this.progreso){
          texto += this.progreso.primer_nombre?this.progreso.primer_nombre:''
          texto += this.progreso.segundo_nombre?' '+this.progreso.segundo_nombre:''
          texto += this.progreso.primer_apellido?' '+this.progreso.primer_apellido:''
          texto += this.progreso.segundo_apellido?' '+this.progreso.segundo_apellido:''
        }
        return texto
      },

      textoFechaClase(){
        if(this.progreso_fila && this.progreso_fila.clase_fecha){
          let clase_fecha = new Date(this.progreso_fila.clase_fecha).toLocaleString()
          return clase_fecha
        }
        return ''
      },
      textoSalon(){
        if(this.progreso_fila && this.progreso_fila.salon){
          let salon = JSON.parse(this.progreso_fila.salon)
          let texto = (salon.sede +' / '+salon.nombre)
          return texto
        }
        return ''
      },
      textoTema(){
        if(this.progreso_fila){
          let texto = this.progreso_fila.nombre
          //let tema = JSON.parse(this.progreso_fila.tema)
          //let texto = tema.nombre
          return texto
        }
        return ''
      },
      textoAsistenciaFecha(){
        if(this.progreso_fila && this.progreso_fila.asistencia_fecha){
          let asistencia_fecha = new Date(this.progreso_fila.asistencia_fecha).toLocaleString()
          return asistencia_fecha
        }
        return ''
      },
      textoAsistenciaUsuario(){
        if(this.progreso_fila && this.progreso_fila.asistencia_usuario){
          let asistencia_usuario = JSON.parse(this.progreso_fila.asistencia_usuario)
          let texto = asistencia_usuario.nombre
          return texto
        }
        return ''
      },
      textoAutorizadoFecha(){
        if(this.progreso_fila && this.progreso_fila.autorizado_fecha){
          let autorizado_fecha = new Date(this.progreso_fila.autorizado_fecha).toLocaleString()
          return autorizado_fecha
        }
        return ''
      },
      textoAutorizadoUsuario(){
        if(this.progreso_fila && this.progreso_fila.autorizado_usuario){
          let autorizado_usuario = JSON.parse(this.progreso_fila.autorizado_usuario)
          let texto = autorizado_usuario.nombre
          return texto
        }
        return ''
      },
      textoEstado(){
        if(this.progreso_fila){
          if(this.progreso_fila.estado=='superado'){
            return 'Superado'
          }else if(this.progreso_fila.estado=='pendiente'){
            return 'Pendiente'
          }else if(this.progreso_fila.estado=='bloqueado'){
            return 'Bloqueado'
          }else if(this.progreso_fila.estado=='autorizado'){
            return 'Autorizado'
          }

        }
        return ''
      },
      observaciones(){
        if(this.progreso_fila && this.progreso_fila.observaciones){
          return  JSON.parse(this.progreso_fila.observaciones)
        }
        return null
      },
      textoConformidadFecha(){
        if(this.progreso_fila && this.progreso_fila.conformidad_fecha){
          let conformidad_fecha = new Date(this.progreso_fila.conformidad_fecha).toLocaleString()
          return conformidad_fecha
        }
        return 'Pendiente'
      },
      
    }
  }
</script>

<style scope>
.progreso {
    border-top: 1px solid #bbb;
    font-size:14px;
    line-height: 14px;
    padding-top: 10px;
}
.progreso  strong{}
.progreso  span{
  font-size: 8px;
  line-height: 8px;
  color:#999;
}

</style>
<template>
  <div class="">
    <div class="row" v-if="registro && tieneNota">
    	<div class="col-md-3">
    		<span>{{registro.nombre}}</span>		
    	</div>
    	<div class="col-md-3">
    		<div v-if="registro.nota">
    			<span>{{registro.nota}}/{{registro.rango_nota}}</span>		
    		</div>
    		<div v-else>
    			<input type="text"
			          v-model="nota"
			          name="Nota"
			          placeholder="0"
			          class="form-control input-nota"
			          size="5"
			          >
			    <span>{{instrucciones}}</span>
		        <button v-if="procesable" class="btn btn-block btn-danger btn-guardarnota" @click="procesarGuardar">
	                Ok
	              </button>
    		</div>
    		
    	</div>
    	<div class="col-md-3">
    		<span>{{acumulado}}/5</span>		
    	</div>
    	
    </div>
    <hr class="separador" v-if="registro && tieneNota"/>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  

  export default {
    name: 'ClasesDetalleNotasRegistro',
    
    data () {
      return {
        nota:null
      }  
    },

    props: {
      registro: Object,
      
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      	saveNota: 'progresos/saveNota',
        fetchClase: 'clases/fetchClase',
      }),
      ...mapMutations({
      }),
      procesarGuardar(){

        this.saveNota({
          registro_id:this.registro.id,
          nota:this.nota,
        }).then(()=>{
          this.fetchClase({clase_id:this.$route.params.id})
        })
      },
      
    },	
    computed: {
      ...mapState({
      }),
      ...mapGetters({
      	getProgreso: 'progresos/getProgreso',
      }),
      tieneNota(){
        if(this.registro && this.registro.rango_nota && this.registro.peso_nota){
          return true
        }
        return false
      },
      acumulado(){
      	if(this.registro && this.registro.rango_nota && this.registro.peso_nota){
      	  let nota_acumulada = (this.registro.nota / this.registro.rango_nota) * this.registro.peso_nota
          nota_acumulada = nota_acumulada.toPrecision(3)*1
          nota_acumulada = nota_acumulada*5/100
          return nota_acumulada
        }
        return null
      },
      instrucciones(){
      	return '0-'+this.registro.rango_nota
      },
      procesable(){
      	if(this.nota && this.nota >= 0 && this.nota <= this.registro.rango_nota){
      		return true;
      	}
      	return false
      }
      
      
    }
  }
</script>

<style scope>
.separador{
	margin:2px; 
	border: 1px solid #ddd !important;
}
.input-nota{
	width: 60px !important;
    display: inline !important;
    margin-right: 10px;
}
.btn-guardarnota{
	width: 60px !important;
    display: inline !important;
    margin: 0 10px;
}
</style>
import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Auth/Login';
import Profile from '@/components/Auth/Profile';
import ClasesLista from '@/components/Clases/ClasesLista';
import ClasesDetalle from '@/components/Clases/ClasesDetalle';
import Progreso from '@/components/Progreso/Progreso';


import store from './store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: ClasesLista,
      meta: { Auth: true, title: 'Inicio' },
    },
    {
      path: '/clase/detalle/:id',
      name: 'clase',
      component: ClasesDetalle,
      meta: { Auth: true, title: 'Clase' },
    },
    {
      path: '/progreso/:id',
      name: 'progreso',
      component: Progreso,
      meta: { Auth: true, title: 'Progreso Académico' },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { Auth: true, title: 'Perfil' },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { Auth: false, title: 'Iniciar sesión' },
      beforeEnter: (to, from, next) => {
        if(store.state.auth.logged) {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  ]
})


//.configurar el router
//para cada cambio de ruta

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !store.state.auth.logged) {
    next({path: '/login'});
  } else {
    if (store.state.auth.logged) {
      store.commit('auth/setUser');
    }
    next();
  }
});

//.para cada cambio de ruta


export default router;
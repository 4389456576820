import Vue from 'vue'
import App from './App.vue'
import config from './config'
import store from './store'
import router from './router'
import './registerServiceWorker'

Vue.config.productionTip = false


//vue resource
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.http.options.root = config.apiUrl;
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Authorization', `Bearer ${window.localStorage.getItem('_token')}`);
  next();
});
//.vue resource

//vue cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
//.vue cookies

//blockui
import BlockUI from 'vue-blockui';
Vue.use(BlockUI);
//.blockui

//vue bootstrap
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//.vue bootstrap

//vee-validate
import VeeValidate, {Validator} from 'vee-validate';
import validatorEs from '@/validator/es';
//import validatorEn from '@/validator/en';
Validator.localize('es', validatorEs);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
//.vee-validate

//vue-tables-2
import {ClientTable} from 'vue-tables-2';
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default');
//.vue-tables-2

new Vue({
	store,
	router,
	render: h => h(App),
	
}).$mount('#app')

import Vue from 'vue';

const state = {
  clases : [],
  cursos: {}
};

const actions = {
  
  fetchClases:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clases').then(
        response =>{
          commit('setClases',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchClase:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase',data).then(
        response =>{
          commit('setClase',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchCurso:({commit},curso_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('temas',{id:curso_id}).then(
        response =>{
          commit('setCurso',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveAsistencia:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/asistencia',data).then(
        response =>{
          commit('setAgendamiento',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveObservacion:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/observacion',data).then(
        response =>{
          commit('setAgendamiento',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveNovedad:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/novedad',data).then(
        response =>{
          commit('setAgendamiento',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  cambiarTipo:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/cambiar_tipo',data).then(
        response =>{
          commit('setAgendamiento',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  saveTema:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('clase/tema',data).then(
        response =>{
          commit('setAgendamiento',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
};

const getters = {
  getCurso: (state) => (curso_id) => {
    if(!state.cursos[curso_id]){
      Vue.set(state.cursos, curso_id, {})
    }
    return state.cursos[curso_id]
  },
};

const mutations = {
  setClases: (state, datos) => {
    state.clases = datos
  },
  setClase: (state, datos) => {
    if(datos.id){
      let index = state.clases.findIndex(element =>{
        return element.id == datos.id
      })
      if(index>=0){ 
        state.clases.splice(index,1,datos)
      }else{
        state.clases.push(datos)
      }
    }
    
  },
  setCurso : (state, datos) => {
    let id = datos.id
    if(!state.cursos[datos.id]){
      Vue.set(state.cursos, datos.id, {})  
    }
    state.cursos[datos.id] = datos
    
  },
  setAgendamiento: (state, datos) => {
    
    if(datos.clase_id){
      let clase = state.clases.find(element =>{
        return element.id == datos.clase_id
      })
      let index = clase.agendamientos.findIndex(element =>{
        return element.id == datos.id
      })
      if(index>=0){
        clase.agendamientos.splice(index,1,datos)
      }
    }else if(datos.clase_anterior){
      let clase = state.clases.find(element =>{
        return element.id == datos.clase_anterior
      })
      if(clase){
        let index = clase.agendamientos.findIndex(element =>{
          return element.id == datos.id
        })
        if(index>=0){
          clase.agendamientos.splice(index,1)
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

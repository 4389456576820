import Vue from 'vue';

const state = {
  user: null,
  logged: !!window.localStorage.getItem('_token'),
  sedeActual: null,
};

const actions = {
  login: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('login', {user: userInput})
        .then(user => {
            window.localStorage.setItem('_token', user.body.token);
            commit('setUser');
            resolve(user);
        })
        .catch(response => {
          commit('setError', response, { root: true });
          reject(response);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  },
  logout: ({commit}) => {
    window.localStorage.removeItem('_token');
    commit('setUser');
  },
  fetchSedeActual:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('sede').then(
        response =>{
          commit('setSedeActual',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
};

const getters = {
  
};

const mutations = {
  //establecemos el user a través del token jwt
  setUser: (state) => {
    if(window.localStorage.getItem('_token')) {
      const token = window.localStorage.getItem('_token');
      const jwtDecode = require('jwt-decode');
      state.user = jwtDecode(token);
      state.logged = true;
    } else {
      state.logged = false;
      state.user = null;

    }
  },
  //establecemos el estado del usuario
  setLogged: (state, logged) => {
    state.logged = logged;
  },
  setSedeActual: (state, data) => {
    state.sedeActual = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

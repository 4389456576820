<template>
  <div class="perfil_wrapper mt-3">

    <div class="container col-lg-6 m-auto">
      <h1 class="text-center text-muted">Mi Cuenta</h1>
      <hr>
      <div class="row">
        <div class="col-3"><strong>Nombre:</strong></div><div class="col-9">{{ user.data.primer_nombre }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Apellido:</strong></div><div class="col-9">{{ user.data.primer_apellido }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Correo:</strong></div><div class="col-9">{{ user.data.email }}</div>
      </div>
      <div class="row">
        <div class="col-3"><strong>Documento:</strong></div><div class="col-9">{{ user.data.numero_documento }}</div>
      </div>
      <div v-if="sedeActual">
        <div  class="row">
          <div class="col-3"><strong>Sede:</strong></div><div class="col-9">{{ sedeActual.nombre }}</div>
        </div>
      </div>
      <hr>
      
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'perfil',
    mounted () {
      if(!this.sedeActual){
        this.fetchSedeActual()
      }
    },
    methods:{
      ...mapActions({
        fetchSedeActual: 'auth/fetchSedeActual',
      }),
      ...mapMutations({
      }),
    },
    computed:{
      ...mapState({
        user: state => state.auth.user,
        sedeActual: state => state.auth.sedeActual,  
      }),
      ...mapGetters({
      }),
    }
  }
</script>

<style scope>

	.perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
	}
</style>
<template>
  <div >
    <hr>
    <div class="row" v-if="agendamiento">

      <div class="col-md-12">
        <b-navbar is-nav>
          <span class="h2">
              <a target="_blank" :href="`#/progreso/${ agendamiento.estudiante_id }`" class="">
              {{ titulo }}</a>
          </span>

          <!--
          <b-badge class="ml-3 mr-auto" variant="info">{{textoTipoSolicitud}}</b-badge>
          
          <b-button size="sm" class="ml-auto" variant="link" 
          v-if="agendamiento.tipo_solicitud=='normal'&& agendamiento.estado=='asignado'" 
          @click="procesarCambiarATutoria">
          Cambiar por Tutoría
          </b-button>
          <b-button size="sm" class="ml-auto" variant="link" 
          v-if="agendamiento.tipo_solicitud=='tutoria' && agendamiento.estado=='asignado'" 
          @click="procesarCambiarAClaseNormal">
          Cambiar por clase
          </b-button>
          -->
        </b-navbar>
        
        <b-alert  :show="tieneNovedad" variant="danger">{{ tituloNovedad }}
          <div class="text-right ml-auto">
          <b-button size="sm" class="ml-auto" variant="success" 
           @click="procesarQuitarNovedad">
          Ya se solucionó el problema
          </b-button>
          </div >
        </b-alert>
      
        <div class="temas" v-if="agendamiento.tema">
          <div v-if="cursoTexto">
            <strong>Curso:</strong><span>{{ cursoTexto }}</span><br>
          </div>
          <strong>Tema:</strong><span>{{ agendamiento.tema.nombre }}</span><br>
          <div v-if="agendamiento.tema.class_topic">
            <strong>Topic:</strong><span>{{ agendamiento.tema.class_topic }}</span>
          </div>
          <div v-if="agendamiento.tema.class_structure">
            <strong>Structure:</strong><span>{{ agendamiento.tema.class_structure }}</span><br>
          </div>
          <div v-if="agendamiento.tema.description">
            <strong>Description:</strong><span>{{ agendamiento.tema.description }}</span>
          </div>
        </div>
        <div v-if="debeSeleccionarTema">
          No hay información del tema.<br>
          Por favor seleccione el tema que verá el estudiante en esta clase:
           <select v-if="curso && curso.temas" v-model="temaIdLocal" >
             <option disabled value="null">--Seleccione un tema--</option>
              <option v-for="temaDisponible in curso.temas" :value="temaDisponible">{{ temaDisponible.nombre }}</option>
            </select>
          
        
          <button v-if="tema_id_local" @click="guardandoTema=true" class="btn btn-success btn-sm ml-2" >Guardar</button>

          <b-modal v-if="tema_id_local" v-model="guardandoTema"  hide-footer :title="textoConfirmarGuardarTema">
            <b-btn variant="success" @click="guardarTema" class="m-2">Si, estoy seguro!</b-btn>
            <b-btn variant="danger" @click="guardandoTema=false" class="m-2">No estoy seguro, voy a verificar.</b-btn>
            <b-btn variant="danger" @click="procesarSinCarpeta" class="m-2">No tengo la carpeta.</b-btn>
            <b-btn variant="danger" @click="procesarErrorTema" class="m-2">No puedo seleccionar el tema correcto.</b-btn>
          </b-modal>


          <div class="alert alert-warning" role="alert" v-if="texto_cambio_tipo">{{ texto_cambio_tipo }}</div>
        </div>
        <div class="mt-3" v-if="agendamiento.estado=='ausencia'">
          <h4>Ausencia reportada</h4>
        </div>

        <div class="mt-3">

          <button v-if="puedeAsistir" @click="guardandoAsistenciaSi=true" class="btn btn-success btn-sm mr-3" >Avance</button>
          
          <button v-if="puedeNoAsistir" @click="guardandoAsistenciaNo=true" class="btn btn-danger btn-sm" >No Asistió</button>
        
          <br>
<!--
          <h5 class="pt-3">Reportar Novedad:</h5>
-->
<!--
          <b-btn size="sm" variant="danger" @click="procesarSinCarpeta" class="m-2">No tengo la carpeta.</b-btn>
-->
<!--
          <b-btn v-if="agendamiento.tipo_solicitud=='normal'" size="sm" variant="danger" @click="procesarErrorTema" class="m-2">El tema no corresponde</b-btn>
-->
          <b-modal  v-model="guardandoAsistenciaSi"  hide-footer :title="textoConfirmarGuardarAsistenciaSi">
            <b-btn variant="success" @click="procesarAsistio('asistio')" class="m-2">Aceptar</b-btn>
            <b-btn variant="danger" @click="guardandoAsistenciaSi=false" class="m-2">No estoy seguro.</b-btn>
          </b-modal>
          <b-modal  v-model="guardandoAsistenciaNo"  hide-footer :title="textoConfirmarGuardarAsistenciaNo">
            <b-btn variant="success" @click="procesarAsistio('no_asistio')" class="m-2">Estoy seguro, {{agendamiento.estudiante.primer_nombre}} NO asistió!</b-btn>
            <b-btn variant="danger" @click="guardandoAsistenciaNo=false" class="m-2">No estoy seguro.</b-btn>
          </b-modal>

        </div>
        <div class="mt-3" v-if="puedeIngresarNota">
          
          <ClasesDetalleExamen @recargar="recargar" v-if="agendamiento.tema.examen"  :agendamiento="agendamiento"></ClasesDetalleExamen>  
          
          <ClasesIngresarNota 
          v-else-if="agendamiento.tema && agendamiento.tema.peso_nota && agendamiento.tema.rango_nota" 
           @addNota="addNota" :agendamiento="agendamiento" />
          
        </div>

        <div v-if="puedeVerificarNotas">
          <ClasesDetalleNotas  :agendamiento="agendamiento" />
        </div>
      </div>  
      <!--
      <div class="col-md-5 observaciones">
          <ClasesDetalleObservaciones @agregarObservacion="agregarObservacion"  :row="agendamiento" />  
      </div>
     -->
    </div>
    
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ClasesDetalleObservaciones from '@/components/Clases/ClasesDetalleObservaciones';
  import ClasesIngresarNota from '@/components/Clases/ClasesIngresarNota';
  import ClasesDetalleNotas from '@/components/Clases/ClasesDetalleNotas';
  import ClasesDetalleExamen from '@/components/Clases/ClasesDetalleExamen';
  
  import utils from '@/modules/utils';

  export default {
    name: 'ClasesDetalleAgendamiento',
    components: {
      ClasesDetalleObservaciones,
      ClasesIngresarNota,
      ClasesDetalleNotas,
      ClasesDetalleExamen
    },
    props:{
      agendamiento: Object
    },
    data () {
      return {
        tema_id_local : null,
        texto_cambio_tipo : null,
        guardandoTema : false,
        guardandoAsistenciaSi: false,
        guardandoAsistenciaNo: false,
        textoNovedad : null
      }
    },
    
    mounted () {
      let curso_id = this.agendamiento.estudiante.curso_actual_id
      let curso = this.getCurso(curso_id)
      if(!curso.temas){
        this.fetchCurso(curso_id)
      }
      if(this.agendamiento.tema_id){
        this.tema_id_local = this.agendamiento.tema_id
      }
    },
    methods:{
      ...mapActions({
        saveAsistencia: 'clases/saveAsistencia',
        saveObservacion: 'clases/saveObservacion',
        cambiarTipo: 'clases/cambiarTipo',
        saveTema: 'clases/saveTema',
        saveNovedad: 'clases/saveNovedad',
        fetchCurso: 'clases/fetchCurso',
        fetchClase: 'clases/fetchClase',
      }),
      ...mapMutations({

      }),
      recargar(){
        this.$emit('recargar')
      },
      procesarAsistio(value){
        this.saveAsistencia({agendamiento_id:this.agendamiento.id,asistencia:value})
        .catch(()=>{})
        .finally(()=>{
          this.guardandoAsistenciaSi=false
          this.guardandoAsistenciaNo=false
          this.fetchClase({clase_id:this.$route.params.id})
          window.location.href = "/#"+this.$route.path
        })
      },
      agregarObservacion(datos){
        this.saveObservacion({agendamiento_id:datos.id,texto:datos.texto}).catch(()=>{})
      },
      addNota(datos){
        //this.saveObservacion({agendamiento_id:datos.id,texto:datos.texto}).catch(()=>{})
      },
      guardarTema(){
        this.saveTema({agendamiento_id:this.agendamiento.id,tema_id:this.tema_id_local.id}).catch(()=>{})
      },
      procesarSinCarpeta(){
        this.saveNovedad({agendamiento_id:this.agendamiento.id,codigo:'sin_carpeta'})
        .then(()=>{
          this.guardandoTema=false
        })
        .catch(()=>{})
      },
      procesarErrorTema(){
        this.saveNovedad({agendamiento_id:this.agendamiento.id,codigo:'error_tema',texto:this.textoNovedad}).then(()=>{
          this.guardandoTema=false
        }).catch(()=>{})

      },
      procesarCambiarATutoria(){
        this.cambiarTipo({tipo:'tutoria',agendamiento_id:this.agendamiento.id}).then(()=>{
          this.guardandoTema=false
        }).catch(()=>{})
      },
      procesarCambiarAClaseNormal(){
        this.cambiarTipo({tipo:'normal',agendamiento_id:this.agendamiento.id}).then(()=>{
          this.guardandoTema=false
        }).catch(()=>{})
      },
      procesarQuitarNovedad(){
        this.saveNovedad({agendamiento_id:this.agendamiento.id,codigo:'quitar_novedad',texto:this.textoNovedad}).then(()=>{
          this.guardandoTema=false
        }).catch(()=>{})
      },
      
    },
    computed: {
      ...mapState({
        cursos: state => state.clases.cursos, 
      }),
      ...mapGetters({
        getCurso: 'clases/getCurso', 
      }),
      temaIdLocal:{
        get () {
          return this.tema_id_local
        },
        set (value) {
          this.tema_id_local = value
          if(this.tema_id_local.tipo_clase != this.agendamiento.tipo_clase){
            this.texto_cambio_tipo = "El estudiante deberá cambiar de clase"
          }else{
            this.texto_cambio_tipo = null
          }
        }
      },
      puedeAsistir(){
        if(this.agendamiento){
          if(this.agendamiento.estado=="asignado"){
              return true
          }
        }
        return false
      },
      puedeNoAsistir(){
        if(this.agendamiento){
          if(this.agendamiento.estado!="ausencia"){
              return true
          }
        }
        return false
      },
      puedeIngresarNota(){
        if(this.agendamiento){
          if(this.agendamiento.estado=="asistido" || this.agendamiento.estado=="ausencia"){
              return true
          }
        }
        return false
      },
      puedeVerificarNotas(){
          if(this.agendamiento.estado == 'asistido' && this.agendamiento.tema  && this.agendamiento.tema.estructura == 'self_assessment'){
              return true
          }else{
            return false
          }
      },
      titulo(){
        let titulo = ''
        titulo += this.agendamiento.estudiante.primer_nombre+' '
        +this.agendamiento.estudiante.primer_apellido

        return titulo
      },
      cursoTexto(){
        if(this.agendamiento && this.agendamiento.tema && this.agendamiento.tema.curso){
          return this.agendamiento.tema.curso.nombre
        }
        return false
      },
      curso(){
        let curso_id = this.agendamiento.estudiante.curso_actual_id
        let curso = this.getCurso(curso_id)
        return curso
      },
      textoTipoSolicitud(){
        if(this.agendamiento ){
          let texto = this.agendamiento.tipo_solicitud
          if(texto=='normal'){
            return 'Clase Normal'
          }
          else if(texto=='tutoria'){
            return 'Tutoría'
          }
          return texto
        }
        return ''
      },
      debeSeleccionarTema(){
        if(this.agendamiento && !this.agendamiento.tema){
          if(this.agendamiento.tipo_solicitud == 'normal'
            || this.agendamiento.tipo_solicitud == 'refuerzo')
          return true
        }
        return false
      },
      textoConfirmarGuardarTema(){
        return 'Apreciado docente, usted está seguro que al estudiante '+this.agendamiento.estudiante.primer_nombre
        +' '+this.agendamiento.estudiante.primer_apellido+' le corresponde en esta clase el tema '+ this.temaIdLocal.nombre+" y es el mismo que queda registrado en la carpeta?. Antes de contestar recuerde que la veracidad de esta información es muy importante para la institución y estará sujeta a auditoría."
      },
      textoConfirmarGuardarAsistenciaSi(){
        return 'Apreciado docente, usted está seguro que el estudiante '+this.agendamiento.estudiante.primer_nombre
        +' '+this.agendamiento.estudiante.primer_apellido+" SI asistió a esta clase?. Antes de contestar recuerde que la veracidad de esta información es muy importante para la institución y estará sujeta a auditoría."
      },
      textoConfirmarGuardarAsistenciaNo(){
        return 'Apreciado docente, usted está seguro que el estudiante '+this.agendamiento.estudiante.primer_nombre
        +' '+this.agendamiento.estudiante.primer_apellido+" NO asistió a esta clase?. Antes de contestar recuerde que la veracidad de esta información es muy importante para la institución y estará sujeta a auditoría."
      },
      tituloNovedad(){
        if(this.agendamiento.novedad == 'sin_carpeta'){
          return 'Reportado ausencia de carpeta. Por favor espere a que el personal de logística solucione el inconveniente'
        }else if(this.agendamiento.novedad == 'error_tema'){
          return 'Reportado error en el tema. Por favor espera a que el área encargado revise el caso.'
        }
        return 'Novedad reportada'
      },
      tieneNovedad(){
        if(this.agendamiento && this.agendamiento.novedad){
          return true
        }
        return false
      }
      
    }
  }
</script>

<style scope>
.observaciones{
  
}
.temas{}
.temas strong{
min-width: 100px;
display: inline-block;
}
.temas span{

}
.cap{
text-transform: capitalize;  
}

</style>
<template>
  <div>
    <hr>
    <div class="row" v-if="agendamiento">

      <div class="col-md-7">
        <h3>
<a target="_blank" :href="`#/progreso/${ agendamiento.estudiante_id }`" class="">
      {{ titulo }}</a>
        </h3>
      
        
        <div class="">
            <div v-if="progresos_bloqueados.length > 0">
              <strong>Bloqueos</strong>
              <div class="progresos" v-for="progreso in progresos_bloqueados">
                <div class="row">
                  <div class="col">
                    {{progreso.temaJoined.nombre}}
                  </div>
                   <div class="col">
                    <button  @click="autorizar_progreso(progreso.id)" class="btn btn-success btn-sm mr-3" >Autorizar</button>
                  </div>
                </div>
                
              </div>
            </div>
            <div v-else>
              <strong>El estudiante no tiene bloqueos</strong>
            </div>
            <transition name="fade">
            <div class="alert alert-warning" role="alert" v-if="mensaje">{{mensaje}}</div>
            </transition>
            <button class="btn btn-primary btn-sm" @click="recargar_progresos">Actualizar Bloqueos</button>
        </div>
        <div class="mt-3" v-if="agendamiento.asistencia==null">

          <button v-if="puedeAsistir" @click="procesarAsistio('asistio')" class="btn btn-success btn-sm mr-3" >Si Asistió</button>

          <button @click="procesarAsistio('no_asistio')" class="btn btn-danger btn-sm" >No Asistió</button>
        </div>
        <div class="mt-3" v-else-if="agendamiento.asistencia=='asistio'">
          <h4>El estudiante asistió a la clase</h4>
        </div>
        <div class="mt-3" v-else-if="agendamiento.asistencia=='asistente'">
          <h4>El estudiante ingresa como asistente</h4>
        </div>
         <div class="mt-3" v-else-if="agendamiento.asistencia=='no_asistio'">
          <h4>El estudiante no asistió a la clase</h4>
        </div>
        
      </div>  
      <div class="col-md-5 observaciones">
          <ClasesDetalleObservaciones @addNota="addNota"  :row="agendamiento" />  
      </div>
    </div>
    
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ClasesDetalleObservaciones from '@/components/Clases/ClasesDetalleObservaciones';
  
  import utils from '@/modules/utils';
  import Vue from 'vue';

  export default {
    name: 'ClasesDetalleAgendamientoLab',
    components: {
      ClasesDetalleObservaciones
    },
    props:{
      agendamiento: Object
    },
    data () {
      return {
        tema_id_local : null,
        texto_cambio_tipo : null,
        progresos_bloqueados: [],
        mensaje : null
      }
    },
    
    mounted () {
      let curso_id = this.agendamiento.estudiante.curso_actual_id
      let curso = this.getCurso(curso_id)
      if(!curso.temas){
        this.fetchCurso(curso_id)
      }
      if(this.agendamiento.tema_id){
        this.tema_id_local = this.agendamiento.tema_id
      }
      this.recargar_progresos()
    },
    methods:{
      ...mapActions({
        saveAsistencia: 'clases/saveAsistencia',
        saveObservacion: 'clases/saveObservacion',
        saveTema: 'clases/saveTema',
        fetchCurso: 'clases/fetchCurso',
      }),
      ...mapMutations({

      }),
      recargar_progresos(){
        if(this.agendamiento && this.agendamiento.estudiante_id){
          Vue.http.post('bloqueos_lab',{estudiante_id:this.agendamiento.estudiante_id}).then(
            response =>{
              this.progresos_bloqueados = response.data
            }
          )
          .catch(error=>{
            console.log(error)
          }).finally(()=>{
            
          })
        }
        
      },
      autorizar_progreso(progreso_id){
        Vue.http.post('desbloquear_lab',{progreso_id:progreso_id}).then(
          response =>{
            this.mensaje = response.data.mensaje
            setTimeout(() => {this.mensaje = null},4000)
            this.recargar_progresos()
          }
        )
        .catch(error=>{
          this.mensaje = error.data.error.message
          setTimeout(() => {this.mensaje = null},4000)
          console.log(error)
        }).finally(()=>{
          
        })
      },
      procesarAsistio(value){
        this.saveAsistencia({agendamiento_id:this.agendamiento.id,asistencia:value}).catch(()=>{})
      },
      addNota(datos){
        this.saveObservacion({agendamiento_id:datos.id,texto:datos.texto}).catch(()=>{})
      },
      guardarTema(){
        this.saveTema({agendamiento_id:this.agendamiento.id,tema_id:this.tema_id_local.id}).catch(()=>{})
      },
      

    },
    computed: {
      ...mapState({
        cursos: state => state.clases.cursos, 
      }),
      ...mapGetters({
        getCurso: 'clases/getCurso', 
      }),
      temaIdLocal:{
        get () {
          return this.tema_id_local
        },
        set (value) {
          this.tema_id_local = value
          if(this.tema_id_local.tipo_clase != this.agendamiento.tipo_clase){
            this.texto_cambio_tipo = "El estudiante deberá cambiar de clase"
          }else{
            this.texto_cambio_tipo = null
          }
        }
      },
      puedeAsistir(){
        
        if(this.agendamiento){
          if(this.agendamiento.tipo_clase=="estandar" || this.agendamiento.tipo_clase=="immersion"){
            if(this.agendamiento.tema_id){
              return true
            }else{
              return false
            }
          }else{
            return true
          }
        }
        if(this.agendamiento.asistencia){
          return false
        }
        return false
      },
      titulo(){
        let titulo = ''
        titulo += this.agendamiento.estudiante.primer_nombre+' '
        +this.agendamiento.estudiante.primer_apellido

        return titulo
      },
      curso(){
        let curso_id = this.agendamiento.estudiante.curso_actual_id
        let curso = this.getCurso(curso_id)
        return curso
      },
      
    }
  }
</script>

<style scope>
.observaciones{
  
}
.temas{}
.temas strong{
min-width: 100px;
display: inline-block;
}
.temas span{

}
.progresos{}
.progresos .row{
  border-top: 1px solid #aaa;
  padding: 5px 0;
}
</style>
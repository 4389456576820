<template>
  <div class="container mt-3">
    <div v-if="progreso">
      <h3 class="text-center">Progreso Academico</h3>
      <h4 class="text-center">{{ texto_estudiante }}</h4>
    </div>
    
    <div v-if="progreso">
      <div class="text-center mb-3 mt-3 row">
        <div class="col"><strong>CLASE</strong></div>
        <div class="col"><strong>ESTADO</strong></div>
        <div class="col"><strong>NOTA</strong></div>
      </div>
      <ProgresoRegistro v-for="progreso_fila in progreso.progresos" :progreso_fila="progreso_fila"/>
    </div>
    <hr>
    <button class="btn btn-primary btn-sm" @click="recargar">Recargar</button>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ProgresoRegistro from '@/components/Progreso/ProgresoRegistro';
  
  import utils from '@/modules/utils';

  export default {
    name: 'Progreso',
    components: {
      ProgresoRegistro
    },
    data () {
      return {
        estudiante_id:null
      }
    },
    props: {
      
    },
    mounted () {
      this.estudiante_id = this.$route.params.id;
      this.fetchProgreso({estudiante_id:this.estudiante_id})
    },
    methods:{
      ...mapActions({
        fetchProgreso: 'progresos/fetchProgreso',
      }),
      ...mapMutations({

      }),
      recargar(){
        this.fetchProgreso({estudiante_id:this.estudiante_id})
      },
    },
    computed: {
      ...mapState({
        progresos: state => state.progresos.progresos, 
      }),
      ...mapGetters({
        getProgreso: 'progresos/getProgreso', 
      }),
      progreso(){
        if(this.estudiante_id){
          return this.getProgreso(this.estudiante_id)
        }
        return null
      },
      texto_estudiante(){
        let texto = ''
        if(this.progreso){
          texto += this.progreso.primer_nombre?this.progreso.primer_nombre:''
          texto += this.progreso.segundo_nombre?' '+this.progreso.segundo_nombre:''
          texto += this.progreso.primer_apellido?' '+this.progreso.primer_apellido:''
          texto += this.progreso.segundo_apellido?' '+this.progreso.segundo_apellido:''
        }
        return texto
      }
    }
  }
</script>

<style scope>
.headers{

}
</style>